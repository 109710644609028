import { animated, useSpring } from '@react-spring/web'
import { Icon } from 'elements/Icon/Icon'
import { Link } from 'elements/Link/Link'
import { usePIMData } from 'hooks/usePIMData'
import { freeBodyScroll, lockBodyScroll } from 'lib/scrollLock'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import {
  Children,
  ReactNode,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import useMeasure from 'react-use-measure'
import { SearchInput } from '~/elements/SearchInput'
import { RegionChange } from '../Footer/components/RegionChange'
import { MobileFlyout } from './components/MobileFlyout'

type Props = {
  open: boolean
  onClose: () => void
}

type ThirdLevelProps = { children: ReactNode; name: string }

const ThirdLevel = ({ children, name }: ThirdLevelProps) => {
  const [open, setOpen] = useState(false)
  const [ref, { height }] = useMeasure()
  const [styles, api] = useSpring(() => ({
    height: open ? height : 0,
    transform: open ? 'scaleY(1)' : 'scaleY(-1)',
  }))

  const handleToggle = () => {
    setOpen((prev) => !prev)
    api.start(() => ({
      height: !open ? height + 32 : 0,
      transform: !open ? 'scaleY(1)' : 'scaleY(-1)',
    }))
  }

  return (
    <div className="border-b border-gray">
      <button
        className={`mb-6 flex w-full flex-row items-center justify-between text-left ${
          open ? 'font-medium' : 'font-normal'
        }`}
        onClick={handleToggle}
      >
        {name}{' '}
        <animated.div style={{ transform: styles.transform }}>
          <Icon name="chevron-up" />
        </animated.div>
      </button>
      <animated.div style={{ height: styles.height }} className="overflow-hidden">
        {Children.map(children, (child) =>
          // @ts-ignore
          isValidElement(child) ? cloneElement(child, { ref }) : null,
        )}
      </animated.div>
    </div>
  )
}

export const NaviMobile = ({ open, onClose }: Props) => {
  const [styles, api] = useSpring(() => ({
    transform: 'translateX(100%)',
  }))
  const navElementRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { data, error, isLoading } = usePIMData<'nav'>('nav')
  const router = useRouter()

  useEffect(() => {
    if (open) {
      return lockBodyScroll()
    } else {
      freeBodyScroll()
    }
  }, [open])

  useEffect(() => {
    api.start(() => ({ transform: open ? 'translateX(0%)' : 'translateX(100%)' }))
  }, [open, api])

  const [windowHeight, setWindowHeight] = useState('100vh')

  // useEffect(() => {
  //   const onResize = () => {
  //     setWindowHeight(
  //       `${window.visualViewport ? window.visualViewport.height : window.innerHeight}px`,
  //     )
  //   }
  //   window.addEventListener('resize', onResize)
  //   return () => window.removeEventListener('resize', onResize)
  // }, [setWindowHeight])

  if (isLoading || error) {
    return null
  }

  return (
    <animated.nav
      style={{ ...styles, height: `calc(${windowHeight} - 56px)` }}
      className="absolute top-0 left-0 z-20 mt-14 flex w-screen translate-x-full flex-col bg-gray-light shadow-lg"
      ref={navElementRef}
    >
      <SearchInput className="my-6 mx-4 w-auto" />
      <div ref={navElementRef} className=" relative w-screen flex-shrink-0 flex-grow flex-nowrap">
        <ul>
          {Array.isArray(data) &&
            data?.map((child, index) => (
              <li className="w-screen p-4 text-xl" key={`${child.url}${child.name}-fl${index}`}>
                {child.children?.length === 0 ? (
                  <Link href={child.url} className="font-normal">
                    {child.name}
                  </Link>
                ) : (
                  <MobileFlyout name={child.name}>
                    <ul>
                      <li onClick={onClose}>
                        <Link
                          href={child.url}
                          className="relative block bg-gray bg-opacity-50 px-4 py-6 font-normal leading-4 hover:text-black focus:text-black focus:outline-none"
                        >
                          Übersicht
                        </Link>
                      </li>
                      {child.children.map((item, index) => (
                        <li key={`${item.url}${item.name}-fl${index}`} className="mx-4 my-6">
                          {item.children?.length === 0 ? (
                            <div onClick={onClose}>
                              <Link href={item.url} className="font-normal">
                                {item.name}
                              </Link>
                            </div>
                          ) : (
                            <ThirdLevel name={item.name}>
                              <div onClick={onClose}>
                                <Link
                                  href={item.url}
                                  className="relative block px-4 py-2 text-base font-medium leading-4 hover:text-black focus:text-black focus:outline-none"
                                >
                                  Übersicht
                                </Link>
                              </div>
                              <ul className="pl-4 pb-6">
                                {item.children.map((thirdLevelItem, index) => (
                                  <li
                                    key={`${thirdLevelItem.url}${thirdLevelItem.name}-tl${index}`}
                                    onClick={onClose}
                                  >
                                    <Link
                                      href={thirdLevelItem.url}
                                      className="text-base font-normal"
                                    >
                                      {thirdLevelItem.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </ThirdLevel>
                          )}
                        </li>
                      ))}
                    </ul>
                  </MobileFlyout>
                )}
              </li>
            ))}
        </ul>
        <div className="absolute bottom-0 w-full bg-gray-medium bg-opacity-10 px-4 pt-8 pb-24 lgx:hidden">
          <RegionChange />
        </div>
      </div>
    </animated.nav>
  )
}
