import Image from '@next/image'
import { Menu, MenuButton, MenuList } from '@reach/menu-button'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { useQuery, useQueryClient } from 'react-query'
import { PAGE_EXISTS } from '~/lib/checkIfPageExistsInRegion'
import { log } from '~/lib/log'

export const RegionChange = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const queryClient = useQueryClient()
  queryClient.invalidateQueries({ queryKey: ['page-exists'] })
  const { data: pageExists } = useQuery(PAGE_EXISTS)
  const [cookies, setCookie] = useCookies(['NEXT_LOCALE'])

  const toggleMenu = () => {
    document
      .querySelector('#language-menu')
      ?.parentElement?.classList.toggle('force-show-reach-menu')
    document
      .querySelector('#account-menu')
      ?.parentElement?.classList.remove('force-show-reach-menu')
  }

  return (
    <div className="relative py-3 text-center lgx:py-0">
      <Menu id="language-menu">
        <MenuButton
          className="lgx:nav-animation relative flex items-center gap-x-4 text-xl font-normal uppercase leading-4 tracking-wider hover:text-black focus:text-black focus:outline-none lgx:gap-x-1 lgx:text-base"
          onTouchStart={toggleMenu}
          onClick={toggleMenu}
        >
          <span className="h-4 w-4">
            {router.locale === 'de_AT' ? (
              <Image
                src={`/assets/austria.png`}
                placeholder="empty"
                width={16}
                height={16}
                alt={'Österreich Flagge'}
              />
            ) : (
              <Image
                src={`/assets/germany.png`}
                placeholder="empty"
                width={16}
                height={16}
                alt={'Österreich Flagge'}
              />
            )}
          </span>
          <span>{router.locale === 'de_AT' ? 'at' : 'de'}</span>
        </MenuButton>
        <MenuList
          className="language-menu z-50 absolute -top-36 lgx:top-7 lgx:-right-12 flex w-48 flex-col gap-4 bg-gray p-6 text-gray-dark shadow-md"
          onMouseLeave={toggleMenu}
        >
          <Link
            className={clsx(
              router.locale === 'de_DE' && 'mb-2 underline',
              'flex w-full justify-between',
            )}
            href={
              pageExists
                ? `https://www.enovis-medtech.de${router.asPath}`
                : 'https://www.enovis-medtech.de/'
            }
            locale="de_DE"
            onClick={async (e) => {
              e.preventDefault()
              setCookie('NEXT_LOCALE', 'de_DE', {
                maxAge: 30 * 24 * 60 * 60, // 30 days,
              })
              await router.push(router.asPath, router.asPath, { locale: 'de_DE' })
            }}
          >
            <span>{t('Germany')}</span>
            <span className="h-4 w-4">
              <Image
                src={`/assets/germany.png`}
                placeholder="empty"
                width={16}
                height={16}
                alt={'Österreich Flagge'}
              />
            </span>
          </Link>
          <Link
            className={clsx(
              router.locale === 'de_AT' && 'underline',
              'flex w-full justify-between',
            )}
            href={
              pageExists
                ? `https://www.enovis-medtech.at${router.asPath}`
                : 'https://www.enovis-medtech.at/'
            }
            locale="de_AT"
            onClick={async (e) => {
              e.preventDefault()
              setCookie('NEXT_LOCALE', 'de_AT', {
                maxAge: 30 * 24 * 60 * 60, // 30 days,
              })
              await router.push(router.asPath, router.asPath, { locale: 'de_AT' })
            }}
          >
            <span>{t('Austria')}</span>
            <span className="h-4 w-4">
              <Image
                src={`/assets/austria.png`}
                placeholder="empty"
                width={16}
                height={16}
                alt={'Österreich Flagge'}
              />
            </span>
          </Link>
        </MenuList>
      </Menu>
    </div>
  )
}
