export const PAGE_EXISTS = 'page-exists'

export const checkPageExists = async ({ locale, url }: { locale: string; url: string }) => {
  try {
    const response = await fetch(
      new URL(
        `https://${process.env.NEXT_PUBLIC_URL}/api/website?path=${url}&locale=${
          locale === 'de_DE' ? 'de_AT' : 'de_DE'
        }`,
      ),
      {
        method: 'HEAD',
      },
    )
    return response.ok
  } catch (error) {
    return false
  }
}
