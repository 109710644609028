import NextImage, { ImageProps } from 'next/image'

const Image = ({ src, ...rest }: ImageProps) => {
  if (!src) {
    console.warn('Img src missing')
    return null
  }

  return (
    <NextImage
      src={typeof src === 'string' && !!src.match(/^http/)?.length ? `${src}${'?t=0'}` : src}
      {...rest}
    />
  )
}

export default Image
