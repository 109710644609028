import { useQuery } from 'react-query'

type ReturnedProps = {
  stageLargePresent: boolean
  navOpen: boolean
}

export const STAGE_LARGE_PRESENT = 'stage-large-present'
export const NAV_OPEN = 'nav-open'

export const useToggleNavBackground = (): ReturnedProps => {
  const { data: stageLargePresent } = useQuery(STAGE_LARGE_PRESENT, () => false)
  const { data: navOpen } = useQuery(NAV_OPEN, () => false)

  return { stageLargePresent, navOpen }
}
