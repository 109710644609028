/**
 * Utility function that checks if a string used as href
 * attribute should result in us rendering a next/link or a
 * normal <a></a> component. This is, because next/link doesn't
 * work for off-site navigation
 *
 * @param {string} url - Url to test
 * @returns boolean
 */
export const isExternal = (url: string) => {
  // https://regex101.com/r/MsgMo0/1
  const regex = /^(https?:\/\/(www\.)?|mailto:|tel:)/g
  return regex.test(url.toString())
}
