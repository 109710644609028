import * as React from 'react'

export enum HeadlineType {
  h1,
  h2,
  h3,
  h4,
}

export type Props = {
  type: HeadlineType
  children: React.ReactNode
  className?: string
}

export const Headline = ({ type, children, className = '' }: Props) => {
  switch (type) {
    case HeadlineType.h1:
      return <h1 className={className}>{children}</h1>
    case HeadlineType.h2:
      return <h2 className={className}>{children}</h2>
    case HeadlineType.h3:
      return <h3 className={className}>{children}</h3>
    case HeadlineType.h4:
      return <h4 className={className}>{children}</h4>
    default:
      return null
  }
}
