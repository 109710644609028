import { fetcher } from 'lib/fetchJson'
import { useRouter } from 'next/router'
import { useQuery, UseQueryOptions } from 'react-query'

export const usePIMData = <ReturnType>(
  url: string,
  options?: Omit<UseQueryOptions<any, Error, ReturnType, string>, 'queryKey' | 'queryFn'> & {
    search: boolean
  },
) => {
  const router = useRouter()
  const pimURL = `/api/backend/${router.locale}/${url}${
    options?.search ? `?search=${options.search}` : ''
  }`

  return useQuery<ReturnType, Error>([pimURL], () => fetcher<ReturnType>(pimURL), {
    enabled: !!url,
    ...options,
  })
}
