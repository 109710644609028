/**
 * Turns an href like produkte/orthesen into
 * something like /produkte/orthesen
 */
export const makeAbsolute = (url: string): string => {
  if (typeof url !== 'string') {
    console.error(`${url} is not a string but typeof ${typeof url}`)
    return ''
  }

  if (url.startsWith('/')) {
    return url
  }

  return `/${url}`
}
