import { Layout } from 'components/Layout/Layout'
import { appWithTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import { useEffect, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from 'react-query/hydration'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'styles/eventModule.css'
import 'styles/global.css'
import 'styles/header.css'
import 'styles/nprogress.css'
import 'styles/pdp.css'
import 'styles/pop.css'
import 'styles/richText.css'
import 'styles/sallyMap.css'
import 'styles/vendor-search.css'
import 'swiper/css'
import { GTM_ID } from '../lib/gtm'

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter()
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            onError(error: Error) {
              if (process.env.NODE_ENV !== 'production') {
                toast.error(error.message)
              }
            },
          },
        },
      }),
  )

  useEffect(() => {
    const handleRouteChangeStart = () => {
      NProgress.start()
      queryClient.setQueryData('toggle-nav', false)
    }
    const handleRouteChangeComplete = () => {
      NProgress.done()
    }
    const handleRouteChangeError = () => {
      NProgress.done()
    }
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [queryClient, router])

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Layout preview={pageProps.preview}>
            <Script src="//app.eu.usercentrics.eu" strategy="beforeInteractive" />
            <Script src="//api.eu.usercentrics.eu" strategy="beforeInteractive" />
            <Script src="//sdp.eu.usercentrics.eu" strategy="beforeInteractive" />
            <Script
              src="/app.eu.usercentrics.eu/browser-ui/latest/loader.js"
              strategy="beforeInteractive"
            />
            <Script
              src="//sdp.eu.usercentrics.eu/latest/uc-block.bundle.js"
              strategy="beforeInteractive"
            />

            <Script
              src="https://app.eu.usercentrics.eu/browser-ui/latest/loader.js"
              id="usercentrics-cmp"
              data-eu-mode="true"
              data-settings-id="vTgV6Qk4zvqetG"
              strategy="beforeInteractive"
            />
            <Script
              src="https://sdp.eu.usercentrics.eu/latest/uc-block.bundle.js"
              strategy="beforeInteractive"
              type="application/javascript"
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_ID}');`,
              }}
              type="text/plain"
              id="google-analytics"
            />
            {process.env.NODE_ENV === 'production' ? (
              <script
                type="text/plain"
                id="userlike-cdn"
                data-usercentrics="Userlike"
                src="https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/7b368f73dce845f6aa94adfcad55702bff17eff7521e4a9f88715c729a022c92.js"
              ></script>
            ) : null}
            <DefaultSeo defaultTitle="Enovis" titleTemplate="Enovis | %s" />
            <Component {...pageProps} />
            <ToastContainer />
          </Layout>
        </Hydrate>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </CookiesProvider>
  )
}

export default appWithTranslation(MyApp)
