export const lockBodyScroll = () => {
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'
    document.body.style.right = '0'
    document.body.style.left = '0'
  }
  
  export const freeBodyScroll = () => {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.right = ''
    document.body.style.left = ''
    window && window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }