import { useRouter } from 'next/router'
import { useState, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { NAV_OPEN, useToggleNavBackground } from 'hooks/useToggleNavBackground'
import { useMedia } from 'hooks/useMedia'
import { Icon } from './Icon/Icon'
import clsx from 'clsx'

type Props = { placeholder?: string; className?: string }

export const SearchInput = ({ placeholder, className }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [inputValue, setInputValue] = useState('')
  const isDesktop = useMedia('lgx')
  const { navOpen } = useToggleNavBackground()
  const queryClient = useQueryClient()

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (!isDesktop && navOpen && inputValue) {
      queryClient.setQueryData<boolean>(NAV_OPEN, () => false)
    }
    if(inputValue) {
      await router.push(`/search/${inputValue}`)
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(
        'group overflow-hidden flex h-12 w-56 flex-row items-center rounded-3xl border border-gray-light bg-white',
        className,
      )}
    >
      <button
        type="submit"
        className="flex h-10 w-10 rounded-full bg-gray-light group-focus:bg-gray group-focus:transition group-focus:duration-500 group-focus:ease-in-out lg:visible lgx:h-12 lgx:w-12"
      >
        <Icon name="search" className="m-auto h-7 w-7" />
      </button>
      <input
        className="ml-4 focus:outline-none w-full"
        type="text"
        id="search-input"
        placeholder={t('Suche')}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue ?? placeholder}
      />
    </form>
  )
}
