import { log } from './log'

class FetchError extends Error {
  response: Response
  data: any
}

export const fetcher = async <ReturnType>(
  ...args: [RequestInfo, RequestInit?]
): Promise<ReturnType> => {
  log('Fetcher URL: ' + args[0])

  try {
    const response = await fetch(...args)
    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json()

    if (response.ok) {
      return data
    }

    const error = new FetchError(response.statusText)
    error.response = response
    error.data = data
    throw error
  } catch (error) {
    if (!error.data) {
      error.data = { message: error.message }
    }
    throw error
  }
}
