import { ReactNode } from 'react'
import { Header } from 'components/Header/Header'
import { Footer } from 'components/Footer/Footer'
import Link from 'next/link'

export type Props = { children: ReactNode; preview: boolean }

export const Layout = ({ children, preview }: Props) => {
  return (
    <div className="max-w-screen relative grid min-h-screen grid-rows-[auto,1fr,auto] overflow-x-hidden">
      <div>
        {preview && (
          <div className="border border-red p-2">
            Preview Mode is active!{' '}
            <Link href="/api/exit-preview" prefetch={false} className="hover:underline">
              Disable
            </Link>
          </div>
        )}
        <Header />
      </div>
      <main className="block">{children}</main>
      <Footer />
    </div>
  );
}
