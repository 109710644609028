import clsx from 'clsx'
import { Link } from 'elements/Link/Link'
import { usePIMData } from 'hooks/usePIMData'
import { useToggleNavBackground } from 'hooks/useToggleNavBackground'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { NavigationType } from 'typings/api'

type Props = {}

export const NaviDesktop = ({}: Props) => {
  const { stageLargePresent: isTransparent } = useToggleNavBackground()
  const { data, isLoading, error } = usePIMData<NavigationType[]>('nav')
  const router = useRouter()
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    const handleRouteChange = () => {
      setHoveredMenuItem(null)
      setDisabled(true)
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  if (isLoading || error) {
    return null
  }

  return (
    <nav
      className={clsx('mr-6 flex h-full w-full items-center', isTransparent && 'text-white')}
      onMouseEnter={() => setDisabled(false)}
    >
      <ul className="flex !flex-grow-0 flex-row flex-nowrap space-x-6">
        {data?.map((child, index) => (
          <li
            key={`${child.url}${child.name}-fl${index}`}
            className={clsx('desktop-nav group flex h-5 items-center')}
            onMouseEnter={() => setHoveredMenuItem(disabled ? null : child.url)}
            onMouseLeave={() => setDisabled(false)}
            onClick={() => setHoveredMenuItem(null)}
          >
            <Link
              href={child.url}
              className={clsx(
                'relative font-normal uppercase leading-4 tracking-wider hover:text-black focus:text-black focus:outline-none',
                router.asPath.split('/').pop() === child.url.split('/').pop()
                  ? 'nav-animation-final'
                  : 'nav-animation',
              )}
            >
              {child.name}
              {hoveredMenuItem === child.url && (
                <div
                  className="absolute -left-6 top-0 h-5"
                  style={{ width: 'calc(100% + 3rem)' }}
                  onMouseEnter={() => setDisabled(false)}
                />
              )}
            </Link>
            {child.children?.length && hoveredMenuItem ? (
              <div
                className={
                  'flyout absolute left-0 top-12 hidden w-screen flex-col flex-wrap group-hover:flex'
                }
              >
                <div className="pointer-events-none h-8" />
                <ul
                  className="flyout z-50 flex-col flex-wrap bg-gray px-20 pt-16 pb-6 group-hover:flex"
                  style={{ maxHeight: '590px' }}
                >
                  {child.children.map((secondLevel, index) => (
                    <li
                      key={`${secondLevel.url}${secondLevel.name}-sl${index}`}
                      className="mb-10"
                      style={{ maxWidth: 'calc(25% - 80px)' }}
                    >
                      <Link
                        href={secondLevel.url}
                        className="mb-4 inline-block text-2xl font-medium"
                      >
                        {secondLevel.name}
                      </Link>
                      {secondLevel.children?.length ? (
                        <ul className="leading-7">
                          {secondLevel.children.map((thirdLevel, index) => (
                            <li
                              key={`${thirdLevel.url}${thirdLevel.name}-tl${index}`}
                              className="mb-6"
                            >
                              <Link href={thirdLevel.url} className="font-normal">
                                {thirdLevel.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </li>
        ))}
      </ul>
    </nav>
  )
}
