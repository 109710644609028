import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import tailwindConfig from '../../tailwind.config'

export const useMedia = (size: keyof typeof tailwindConfig['theme']['screens']): boolean => {
  //! the ready state is to fix SSR issues by waiting for window
  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (window) {
      setReady(true)
    }
  }, [])
  const desktop = useMediaQuery({
    query: `(min-width: ${parseInt(tailwindConfig.theme.screens[size], 10)}px)`,
  })

  return ready ? desktop : null
}
