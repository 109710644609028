const colors = require('tailwindcss/colors')

module.exports = {
  content: ['./src/**/*.tsx'],
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: {
        DEFAULT: colors.black,
        light: '#333333',
      },
      white: {
        DEFAULT: colors.white,
        transparent: 'rgba(255,255,255,.25)',
      },
      gray: {
        DEFAULT: '#E0E0E0',
        light: '#f7f7f7',
        medium: '#A2A3A1',
        dark: '#4D4D47',
      },
      red: {
        DEFAULT: '#C01129',
        light: '#CD2B41',
        dark: '#940F1F',
        active: '#F54059',
      },
    },
    screens: {
      xs: '450px',
      sm: '600px',
      md: '768px',
      lg: '1024px',
      lgx: '1200px',
      xl: '1440px',
      '2xl': '1920px',
    },
    extend: {
      minWidth: {
        60: '240px',
        '1/3': '32%',
        '35vw': '35vw',
      },
      maxWidth: {
        89: '356px',
        text: '52vw',
        '43vw': '43vw',
        '90%': '90%',
        '75%': '75%',
      },
      maxHeight: {
        680: '680px',
        '75%': '75%',
      },
      width: {
        125: '500px',
        double: '200vw',
        '35vw': '35vw',
        '27vw': '27vw',
      },
      height: {
        '133vw': '133vw',
        '75vw': '75vw',
        'hd-vw': '56.25vw',
        '33vw': '33vw',
        '38vw': '38vw',
        '40vw': '40vw',
        '100vh': '100vh',
        'stage-desktop': '100vh',
        'stage-mobile': 'calc(100vh - 56px)',
        'slide-mobile': 'calc(92vw - 20px)',
        'slide-ipad': 'calc(50vw - 20px)',
      },
      margin: {
        '60vw': '60vw',
      },
      cursor: {
        grab: 'grab',
      },
      borderRadius: {
        '4xl': '3.75rem',
      },
      fontSize: {
        'p-small': ['12px', '20px'],
        'h1-desk': ['64px', '72px'],
        'h2-desk': ['42px', '50px'],
        'h3-desk': ['28px', '36px'],
        'h4-desk': ['20px', '28px'],
        'h1-mobile': ['40px', '48px'],
        'h2-mobile': ['32px', '40px'],
        'h3-mobile': ['20px', '28px'],
        'h4-mobile': ['16px', '20px'],
        'h1-large': ['80px', '80px'],
        'h2-large': ['52px', '60px'],
        'h3-large': ['40px', '48px'],
        'h4-large': ['40px', '48px'],
        // dynamic font sizes
        'h1-mobile-dynamic': [
          'clamp(28px, 0.8898rem + 4.3011vw, 40px)', // 320px - 599px
          'clamp(38px, 1.5148rem + 4.3011vw, 50px)',
        ],
        'h1-desk-sm-dynamic': [
          'clamp(52px, 2.1862rem + 2.8369vw, 64px)', // 600px - 1023px
          'clamp(62px, 2.8112rem + 2.8369vw, 74px)',
        ],
        'h1-desk-lg-dynamic': [
          'clamp(34px, -0.0203rem + 3.3520vw, 64px)', // 1024px - 1919px
          'clamp(44px, 0.6047rem + 3.3520vw, 74px)',
        ],
        'h1-large-dynamic': [
          'clamp(70px, 2.4971rem + 1.5649vw, 80px)', // 1920px
          'clamp(70px, 2.4971rem + 1.5649vw, 80px)',
        ],
        'h3-desk-dynamic': [
          'clamp(18px, -0.7574rem + 3.9216vw, 28px)',
          'clamp(26px, -0.2574rem + 3.9216vw, 36px)',
        ],
        hidden: '0',
      },
      lineHeight: {
        slack: 3,
        12: '3rem',
        15: '3.75rem',
        18: '4.5rem',
      },
      inset: {
        5.5: '5.5vw',
        '-30': '-7.5rem',
      },
      lineClamp: {
        7: '7',
        8: '8',
        9: '9',
        10: '10',
      },
      transitionProperty: {
        filter: 'filter',
      },
      gridTemplateColumns: {
        '1-auto': '1fr auto',
        fit: 'repeat(auto-fit, minmax(0, 1fr))',
        'fit-40': 'repeat(auto-fit, 40px)',
        'fit-200': 'repeat(auto-fit, 200px)',
      },
    },
    boxShadow: {
      sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '3xl': '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      none: 'none',
      'sally-dot': '0px 0px 15px 3px #FFFFFF',
    },
    fontFamily: {
      sans: [
        'Uni Neue',
        'HelveticaNeue-Light',
        'Helvetica Neue Light',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'Lucida Grande',
        'sans-serif',
      ],
    },
    container: {
      padding: {
        DEFAULT: '1rem',
        sm: '2rem',
        lg: '5rem',
        xl: '5rem',
        '2xl': '10rem',
      },
    },
    backgroundImage: {
      gradient: 'linear-gradient(188.05deg, rgba(0, 0, 0, 0) 6.2%, #000000 117.32%)',
    },
  },
  plugins: [require('tailwind-children'), require('@tailwindcss/line-clamp')],
}
