import Image from '@next/image'
import { Accordion as AccordionReach } from '@reach/accordion'
import '@reach/accordion/styles.css'
import { CustomAccordionItem } from 'components/Accordion/CustomAccordionItem'
import { Link } from 'elements/Link/Link'
import { useMedia } from 'hooks/useMedia'
import { usePIMData } from 'hooks/usePIMData'

import facebook from './assets/facebook.svg'
import instagram from './assets/instagram.svg'
import linkedin from './assets/linkedin.svg'
import xing from './assets/xing.svg'
import youtube from './assets/youtube.svg'

export const Footer = () => {
  const isDesktop = useMedia('lgx')
  const { data, isLoading, error } = usePIMData<any>('footer')

  if (isLoading || error) {
    return null
  }

  return (
    <footer className="bg-gray-light relative">
      {isDesktop ? (
        <ul className="flex w-full justify-between p-16 pb-0 mx-auto max-w-screen-xl">
          <li className="text-3xl font-bold text-black-light">
            {data.address_title}
            <div
              className="mt-4 font-normal"
              dangerouslySetInnerHTML={{ __html: data.address }}
            ></div>
          </li>
          {data?.nav
            ? data.nav.map((child) => (
                <li key={child.name} className="text-3xl font-bold text-black-light">
                  {child.url ? (
                    <Link className="font-bold" href={child.url} title={child.name}>
                      {child.name}
                    </Link>
                  ) : (
                    <span className="font-bold">{child.name}</span>
                  )}
                  <ul>
                    {child.children.map((item) => (
                      <li key={item.name} className="my-4 text-base font-normal">
                        {item.url ? (
                          <Link
                            href={item.url}
                            title={item.name}
                            className="font-medium hover:text-red"
                          >
                            {item.name}
                          </Link>
                        ) : (
                          <span>{item.name}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))
            : null}
        </ul>
      ) : (
        <AccordionReach multiple>
          {data.address_title ? (
            <CustomAccordionItem
              key={data.address_title}
              itemHeading={data.address_title}
              className="px-4"
            >
              <div className="p-4" dangerouslySetInnerHTML={{ __html: data.address }}></div>
            </CustomAccordionItem>
          ) : null}

          {data?.nav
            ? data.nav.map((child) => (
                <CustomAccordionItem key={child.name} itemHeading={child.name} className="px-4">
                  <div className="p-4">
                    {child.children.map((item) => (
                      <ul key={item.name}>
                        <li className="my-4 text-base font-normal">
                          {item.url ? (
                            <Link href={item.url} title={item.name} className="font-medium">
                              {item.name}
                            </Link>
                          ) : (
                            <span>{item.name}</span>
                          )}
                        </li>
                      </ul>
                    ))}
                  </div>
                </CustomAccordionItem>
              ))
            : null}
        </AccordionReach>
      )}
      <ul className="grid grid-flow-row grid-cols-2 items-center justify-items-center px-4 pt-11 pb-6 lgx:grid-cols-fit lgx:gap-8 lgx:px-16">
        {data?.logos
          ? data.logos.map((logo) => (
              <li key={logo.title} className="relative block h-[56px] max-w-[140px] lg:max-w-xs">
                <Image
                  src={logo.url}
                  alt={logo.alt}
                  width={140}
                  height={56}
                  style={{ height: '56px', objectFit: 'contain' }}
                />
              </li>
            ))
          : null}
      </ul>
      <ul className="grid grid-cols-fit-40 items-center justify-center gap-6 bg-black-light p-6 md:gap-8">
        <li>
          <Link href="https://www.facebook.com/EnovisDach" className="block h-10 w-10">
            <Image src={facebook} alt="Facebook" width={40} height={40} />
          </Link>
        </li>
        <li>
          <Link href="https://www.instagram.com/enovis_d_a_ch/" className="block h-10 w-10">
            <Image src={instagram} alt="Instagram" width={40} height={40} />
          </Link>
        </li>
        <li>
          <Link href="https://www.linkedin.com/company/enovis-d-a-ch" className="block h-10 w-10">
            <Image src={linkedin} alt="LinkedIn" width={40} height={40} />
          </Link>
        </li>
        <li>
          <Link href="https://www.xing.com/pages/djodeutschland" className="block h-10 w-10">
            <Image src={xing} alt="Xing" width={40} height={40} />
          </Link>
        </li>
        <li>
          <Link href="https://www.youtube.com/user/DJOGermany" className="block h-10 w-10">
            <Image src={youtube} alt="Youtube" width={40} height={40} />
          </Link>
        </li>
        {/* <li className='col-span-5 row-start-2 text-center lgx:row-start-1 lgx:flex lgx:items-center lgx:absolute right-3 lgx:right-10'><RegionChange/></li> */}
      </ul>
    </footer>
  )
}
