import NextLink from 'next/link'
import { ReactNode } from 'react'

import { isExternal } from 'lib/externalLink'
import { makeAbsolute } from 'lib/makeAbsolute'

export type Props = {
  children: ReactNode
  href: string
  className?: string
  title?: string
  target?: '_blank' | '_self' | '_parent' | 'top'
  rel?: string
  locale?: string
}

export const Link = ({ children, href, className, title, target, rel, locale }: Props) => {
  if (!href) return null

  if (!isExternal(href)) {
    return (
      <NextLink href={makeAbsolute(href)} className={className} title={title} locale={locale}>
        {children}
      </NextLink>
    )
  }

  return (
    <a
      href={href}
      className={className}
      title={title}
      target={target ?? '_self'}
      rel={rel || target === '_blank' ? 'noopener noreferrer' : undefined}
    >
      {children}
    </a>
  )
}
