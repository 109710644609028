import { useSpring, animated } from '@react-spring/web'
import { Icon } from 'elements/Icon/Icon'
import { ReactNode } from 'react'

type Props = { children: ReactNode; name: string }

export const MobileFlyout = ({ name, children }: Props) => {
  const [secondLevelStyles, secondLevelApi] = useSpring(() => ({ transform: 'translateX(100%)' }))

  const openSecondLevel = () => {
    secondLevelApi.start(() => ({ transform: 'translateX(0%)' }))
  }

  const handleBackToToplevel = () => {
    secondLevelApi.start(() => ({ transform: 'translateX(100%)' }))
  }

  return (
    <>
      <button onClick={openSecondLevel}>{name}</button>
      <animated.div
        style={{
          ...secondLevelStyles,
          // top: 96,
          top: 22, //switch back to 96 when search is implemented
        }}
        className="absolute left-0 bottom-0 right-0 z-10 translate-x-full overflow-auto bg-gray-light pt-4 pb-32"
      >
        <div className="w-screen text-xl">
          <button
            onClick={handleBackToToplevel}
            className="flex items-center px-4 pb-4 font-medium"
          >
            <Icon name="arrow-left" className="mr-3" /> {name}
          </button>
          <hr className="h-px border-0 bg-gray" />
          {children}
        </div>
      </animated.div>
    </>
  )
}
