import { NaviDesktop } from 'components/Navigation/NaviDesktop'
import { NaviMobile } from 'components/Navigation/NaviMobile'
import { Icon } from 'elements/Icon/Icon'
import { Link } from 'elements/Link/Link'
import { useMedia } from 'hooks/useMedia'
import { NAV_OPEN, useToggleNavBackground } from 'hooks/useToggleNavBackground'
import { useTranslation } from 'next-i18next'
import NextImage from 'next/image'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { SearchInput } from '~/elements/SearchInput'
import { fetcher } from '~/lib/fetchJson'
import { RegionChange } from '../Footer/components/RegionChange'
import newLogo from './components/Logo_Enovis_FullColor-Small-RGB.png'

type Props = {}

export const Header = ({}: Props) => {
  const isDesktop = useMedia('lgx')
  const { data } = useQuery(['time'], async () => {
    const data = await fetcher<{ dateTime: string }>('/time')
    return data
  })
  const { t } = useTranslation()
  const { stageLargePresent, navOpen } = useToggleNavBackground()
  // this extra variable is nescessary so the header stays normal when no StageLarge is present on the page
  const [transparent, setTransparent] = useState(stageLargePresent)
  const queryClient = useQueryClient()

  useEffect(() => {
    setTransparent(stageLargePresent)
  }, [stageLargePresent])

  const handleToggleNav = () => {
    queryClient.setQueryData<boolean>(NAV_OPEN, (prev) => !prev)
  }

  useEffect(() => {
    const body = document.querySelector('body')
    if (body === null) return
    else if (navOpen) {
      body.classList.add('overflow-hidden')
    } else {
      body.classList.remove('overflow-hidden')
    }
  }, [navOpen])

  return (
    <>
      <header
        className={`relative z-50 flex h-14 w-screen flex-row items-center justify-between overflow-hidden p-3 transition-colors lgx:h-20 lgx:overflow-visible lgx:px-10 ${
          transparent && !navOpen ? 'bg-transparent' : 'bg-white'
        }`}
        onMouseEnter={() => {
          // only change opacity when a StageLarge is present on page
          stageLargePresent && setTransparent(false)
        }}
        onMouseLeave={() => {
          stageLargePresent && setTransparent(true)
        }}
      >
        <Link href="/" className="relative my-auto mr-auto flex h-full w-72 items-center lgx:mr-10">
          <NextImage
            src={newLogo}
            alt="Enovis Logo"
            height={45}
            width={192}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Link>
        {isDesktop ? <NaviDesktop /> : null}
        <ul className="text-grey-dark flex flex-row items-center">
          {isDesktop ? (
            <li className="mr-4 lgx:mr-6">
              <SearchInput className="overflow-hidden" />
            </li>
          ) : null}

          {/* <li className="hidden lgx:block lgx:mr-6">
        <Wobble changeColor={true}>
          <button
            className="h-10 lgx:h-12 w-10 lgx:w-12 flex rounded-full bg-gray-light focus:outline-none focus:bg-gray focus:transition focus:duration-500 focus:ease-in-out"
            onClick={() => {
              window.alert('Will be implemented later')
            }}
            title={t('Storeswitch')}
          >
            <Icon name="world" className="w-6 lgx:w-7 h-6 lgx:h-7 m-auto" />
          </button>
        </Wobble>
      </li>
      <li className="mr-4 lgx:mr-6">
        <Wobble changeColor={true}>
          <Link
            href="/cart"
            className="h-10 lgx:h-12 w-10 lgx:w-12 flex rounded-full bg-gray-light focus:outline-none focus:bg-gray focus:transition focus:duration-500 focus:ease-in-out"
            title={t('Cart')}
          >
            <Icon name="cart" className="w-6 lgx:w-7 h-6 lgx:h-7 m-auto" />
          </Link>
        </Wobble>
      </li>
      <li className="mr-4 lgx:mr-0">
        <Wobble changeColor={true}>
          <button
            className="h-10 lgx:h-12 w-10 lgx:w-12 flex rounded-full bg-gray-light focus:outline-none focus:bg-gray focus:transition focus:duration-500 focus:ease-in-out"
            onClick={() => {
              window.alert('Will be implemented later')
            }}
            title={t('Account')}
          >
            <Icon name="account" className="w-6 lgx:w-7 h-6 lgx:h-7 m-auto" />
          </button>
        </Wobble>
      </li> */}
          <li className="hidden lgx:block">
            <RegionChange />
          </li>
          <li className="lgx:hidden">
            <button
              className="flex h-10 w-10 rounded-full bg-gray-light focus:bg-gray focus:outline-none focus:transition focus:duration-500 focus:ease-in-out lgx:h-12 lgx:w-12"
              onClick={() => handleToggleNav()}
            >
              <Icon
                name={navOpen ? 'cross' : 'mobile-menu'}
                // remove padding when search is implemented
                className={`m-auto h-6 w-6 lgx:h-7 lgx:w-7 ${navOpen ? 'p-0' : 'p-1'}`}
              />
            </button>
          </li>
        </ul>
      </header>
      {isDesktop ? null : <NaviMobile open={navOpen} onClose={() => handleToggleNav()} />}
    </>
  )
}
