export const GTM_ID = 'GTM-N5GQC5K'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export const pageview = (url) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  })
}
