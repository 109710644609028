import { useState, useRef, useEffect } from 'react'
import { Headline, HeadlineType } from '../../elements/Headline/Headline'
import { Icon } from '../../elements/Icon/Icon'
import { AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion'
import '@reach/accordion/styles.css'
import { animated, useSpring } from '@react-spring/web'
import { useElementSize } from '@charlietango/hooks'

export type ITProps = {
  itemHeading: string
  children: React.ReactNode
  className?: string
}

export const CustomAccordionItem = ({ itemHeading, children, className }: ITProps) => {
  const AnimatedPanel = animated(AccordionPanel)
  const AnimatedItem = animated(AccordionItem)
  const [isOpen, setIsOpen] = useState(false)
  const [buttonRef, { height: buttonHeight }] = useElementSize()
  const [panelRef, { height: panelHeight }] = useElementSize()
  const accItem = useRef<HTMLDivElement>()

  const expand = useSpring({
    config: { friction: 20 },
    height: isOpen
      ? `${Math.floor(panelHeight) + Math.floor(buttonHeight) + 32}px`
      : `${Math.floor(buttonHeight) + 32}px`,
  })

  const fade = useSpring({
    config: { duration: 300 },
    opacity: isOpen ? `1` : `0`,
  })

  const rotate = useSpring({
    config: { friction: 15 },
    rotate: isOpen ? 0 : 90,
  })

  useEffect(() => {
    if (isOpen) {
      const rect = accItem.current.getBoundingClientRect()
      if (window.innerHeight - rect.top < panelHeight) {
        const scrollHeight =
          rect.top - panelHeight > 0 ? panelHeight : panelHeight - (rect.top - panelHeight) * -1
        window.scrollBy({
          top: Math.floor(scrollHeight),
          behavior: 'smooth',
        })
      }
    }
  }, [panelHeight, isOpen])

  return (
    <AnimatedItem className={`overflow-hidden ${className}`} style={expand} ref={accItem}>
      <AccordionButton
        ref={buttonRef}
        onClick={() => setIsOpen((prev) => !prev)}
        className={`acc-button flex w-full items-center justify-between border-t border-gray py-4 focus:bg-gray-light focus:bg-opacity-70 focus:outline-none`}
      >
        <Headline
          type={HeadlineType.h4}
          className={'mb-0 max-w-[85%] text-left lg:px-4 2xl:text-h4-desk'}
        >
          {itemHeading}
        </Headline>
        <div className={'mr-2 h-3.5 w-3.5 transform lg:mr-4 xl:h-4 xl:w-4 2xl:h-3 2xl:w-7'}>
          <animated.div style={rotate} className={`absolute h-full w-full origin-center`}>
            <Icon name="minus" className={`absolute h-full w-full origin-center`} />
          </animated.div>
          <Icon name="minus" className={`absolute h-full w-full origin-center`} />
        </div>
      </AccordionButton>

      <div ref={panelRef}>
        <AnimatedPanel style={fade}>{children}</AnimatedPanel>
      </div>
    </AnimatedItem>
  )
}
